@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-sider-collapsed .logo img {
  width: 100%;
}
.ant-layout-header {
  background: #22282d;
  color: #fff;
}

.ant-layout-sider-collapsed .logo span {
  display: none;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 33px 10px;
  color: #fff;
  background: #22282d;
  cursor: pointer;
}

.logo img {
  display: block;
  width: 70%;
  margin: 0 auto;
}
.service-desk-link {
  padding-left: 24px;
  color: #a6b5cc;
  transition: color 0.4s;
}
.service-desk-link:hover {
  color: #fff;
}
.service-desk-link span {
  padding-left: 6px;
}
.ant-layout-sider-collapsed .service-desk-link {
  text-align: center;
  padding: 0;
  display: block;
  font-size: 20px;
}
.ant-layout-sider-collapsed .service-desk-link span {
  display: none;
}

.logo span {
  position: relative;
  top: 5px;
}

.custom-menu-item {
  display: block;
  width: 100%;
  height: 100%;
}

/* #nprogress .bar {
  background: #1890ff;
}

#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}

#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
} */

.has-error {
  font-size: 10px;
}
.ant-layout-sider-children {
  overflow: auto;
}

.ant-layout-sider {
  background: #38414a;
}

.ant-menu.ant-menu-dark {
  background: #38414a;
}
#menu .ant-menu-item {
  margin: 0 !important;
  height: 55px;
  display: flex;
  align-items: center;
}

.ant-menu.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #596776;
  border-bottom: 1.5px solid #c3cad2;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #012d6d;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
}

.ant-layout-sider-trigger {
  background: #596776;
}
.ant-layout-sider-zero-width-trigger {
  top: 10px;
  z-index: 9999;
}
@media (max-width: 600px) {
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
}
.export-btn {
  margin: 0.8%;
}

/* GLOBAL STYLES: */
.divider {
  background: #f0f2f6;
  margin-top: 4%;
  width: 5000px; /* TODO: WTF??? */
  padding-left: 0;
  margin-left: -25px;
  padding: 5px;
  font-size: 1.4em;
}

h1 {
  font-size: 2em;
}

.modal-button {
  width: 100%;
  overflow: hidden;
}

/* HEADER styles */

.button-rocket {
  display: flex;
  align-items: center;
  background: #f9ae3b 0% 0% no-repeat padding-box;
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 1rem;
  height: 45px;
  font-family: 'Montserrat', sans-serif;
}
.button-rocket:hover {
  background-color: #f9ad3bdc;
  color: #fff;
}

.header-new-camp-middle {
  display: flex;
  align-items: center;
}
.button-rocket img {
  width: 45px;
  transition: 0.3s;
}
.button-rocket:hover img {
  transform: translateY(-5px);
}

.header-wallet {
  text-align: right;
  width: 30%;
  white-space: nowrap;
  margin-left: 20px;
}
.coins-value {
  color: #032b6d;
  font: Bold 1.4rem/32px Source Sans Pro;
  margin-right: 20px;
}
.wallet {
  width: 28px;
  padding-bottom: 10px;
  margin-right: 7px;
}
.header-right {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-end;
}

.header-right svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.header-dropdown {
  top: 50px !important;
}

.header-action-btn {
  color: #bcbccb;
  margin-left: 20px;
}
.header-action-btn:nth-child(2) {
  margin-right: 10px;
}
.header-action-btn:hover {
  color: #2d71b6;
  transition: 0.3s;
}
.header-username {
  font-weight: 600;
}
.profile-avatar {
  width: 41px;
  height: 41px;
  object-fit: cover;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
}

.down-arrow svg {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.header-right-spinner {
  width: 30%;
}
.header-right-user {
  white-space: nowrap;
  cursor: pointer;
  float: right;
  margin-right: 20px;
  padding-left: 10px;
}
.billingDataMissingNotification {
  height: 40px;
  background: rgb(250, 191, 98);
  display: flex;
  align-content: center;
  justify-content: center;
  color: #032b6d;
}
/* End Header styles */

@media (max-width: 1500px) {
  .coins-value {
    font-size: 1.2rem;
  }
  /* .header-username {
    display: none;
  } */
  .button-rocket {
    font-size: 0.9rem;
    padding: 0.5rem 0.7rem;
    height: 40px;
  }
  .header-right {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 900px) {
  .header-username,
  .header-wallet,
  .header-action-btn {
    display: none;
  }
}

.Registers_createRegisterBtn__lTUhw {
  margin-bottom: 30px;
}

.Registers_actions__2jtUM {
  display: flex;
  align-items: center;
}

.Registers_actions__2jtUM > div {
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}

.Registers_actions__2jtUM > div:first-child {
  margin-right: 12px;
}

.Registers_actions__2jtUM > div:last-child {
  margin-right: 0;
}

.Registers_actions__2jtUM .ant-popover-inner-content {
  padding: 5px 8px !important;
}

.Registers_addOrEditRegisterModal__2omi6 .ant-modal-header .ant-modal-title {
  font-size: 1.4em;
}

.Registers_modalFormWrapper__3JNse,
.Registers_selectImgWrapper__nVmPh {
  display: flex;
}

.Registers_addOrEditRegisterModal__2omi6 .ant-select-selection-item-content,
.Registers_addOrEditRegisterModal__2omi6 .ant-select-selection-item-content {
  display: flex !important;
}

.Registers_addOrEditRegisterModal__2omi6 .ant-space-horizontal {
  align-items: flex-start;
}

.Registers_modalFormWrapper__3JNse .ant-select-selection-search-input {
  margin-left: 0 !important;
}

.Registers_selectDropdownWithImg__1PbrG .ant-select-item-option-content {
  display: flex !important;
}

.Registers_hideSelectDropdown__3ANd7 {
  display: none;
}

.Registers_modalFormWrapper__3JNse {
  flex-direction: column;
}

.Registers_widthModal50__s0hrY {
  width: 50%;
}

.Registers_marginBotModal__32c_L {
  margin-bottom: 20px;
}

.Registers_registersTable__18IY1 {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
th,
td {
  min-width: 80px;
}

.Registers_registersTable__18IY1 table thead th th:hover {
  font-size: 1.05em !important;
  background-color: #b3c8e617;
  color: #707070;
  font-weight: 600 !important;
}

.Registers_registersTable__18IY1 table tbody {
  font-size: 0.9rem;
  color: #707070;
}
.Registers_registersTable__18IY1 table tbody tr td {
  font-weight: 400;
}
.Registers_creatorAndDateCreated__1bQLF {
  font-size: 0.9em;
  font-weight: 500;
  color: #00000099;
}

.Registers_tableFooter__32hEt {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.Registers_paginationDesription__HHB5K {
  margin-left: 20px;
  font-size: 0.9rem;
  color: #707070;
}
.Registers_headingActions__3k8zQ {
  display: flex;
  margin-bottom: 30px;
  flex-direction: row-reverse;
  margin-bottom: 30px;
  justify-content: space-between;
}

.Registers_headingActions__3k8zQ > div {
  margin-right: 20px;
}

.Registers_searchInput__hFYGf {
  margin: 0 -5px;
  display: flex;
  flex-direction: row;
  width: 300px;
}
.Registers_addButton__i2OKI,
.Registers_addButton__i2OKI:focus {
  background: #1abc9c;
  border: 1px solid #1abc9c;
  font-weight: bold;
}
.Registers_paginationBackground__3jOsc {
  background-color: white;
}
.Registers_addButton__i2OKI:hover {
  background: #1abc9cce;
}
.Registers_cancelButton__1Twj- {
  color: #fff !important;
  background: #f25454d4 !important;
  border-color: #f25454d4 !important;
  font-weight: bold;
}
.Registers_cancelButton__1Twj-:hover {
  background: #f25454ac;
  color: #fff;
  border-color: #f25454ac;
}
.Registers_pageSizeButton__3X2hU {
  margin-left: 14px;
}
.Registers_invoiceCustomInput__3P8BO {
  border: none;
  padding: 0;
  outline: none;
  border-bottom: 2px dotted #1abc9c9e;
  text-align: center;
  width: 100%;
  color: #1abc9c;
  background: transparent;
  font-size: 13px;
  height: 27px;
}

.Registers_invoiceCustomInput__3P8BO input {
  text-align: center;
  color: #1abc9c;
}
.Registers_invoiceCustomInput__3P8BO .ant-select-selector {
  border: none !important;
  justify-content: center;
  background: transparent !important;
}
.Registers_invoiceCustomInput__3P8BO .ant-select-selector .ant-select-selection-item div {
  justify-content: center;
  background: transparent !important;
}
.Registers_formTwoSetFields__QHUXF {
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: 45px;
  font-size: 13px;
}
.Registers_invoiceAndBuyerFormBoxes__3AK85 {
  padding: 22px !important;
  background-color: white;
  color: #707070d6;
  font-weight: 500;
}
.Registers_formTwoSetFields__QHUXF:nth-child(odd) {
  background-color: #1abc9c0e;
}
.Registers_invoicePlaceholderDisabled__36dgh::-webkit-input-placeholder {
  color: #f25454ea;
}
.Registers_invoicePlaceholderDisabled__36dgh:-ms-input-placeholder {
  color: #f25454ea;
}
.Registers_invoicePlaceholderDisabled__36dgh::-ms-input-placeholder {
  color: #f25454ea;
}
.Registers_invoicePlaceholderDisabled__36dgh::placeholder {
  color: #f25454ea;
}
.Registers_invoiceCustomInput__3P8BO[disabled] {
  background-color: transparent;
}
.Registers_invoiceCustomInput__3P8BO:focus {
  box-shadow: none;
  border-color: #1abc9c;
}
.Registers_invoiceSubHeader__1Jk7q {
  margin-bottom: 14px;
  color: #707070d6;
  font-weight: 500;
}
.Registers_customerColumn__15Vul {
  width: 25%;
  display: inline-block;
  padding: 0 10px;
}
.Registers_registerForm__1mY7B {
  width: 100%;
}
.Registers_formColumn__2h6q6 {
  text-align: center;
  padding: 0 10px;
  width: 10%;
  display: inline-block;
  margin-left: 1px;
}
.Registers_cursorPointer__hCJVV {
  cursor: default;
}
.Registers_itemsTable__PzX1n .ant-empty.ant-empty-normal {
  display: none;
}
.Registers_itemsTable__PzX1n .ant-table-thead .ant-table-cell,
.Registers_itemsTable__PzX1n .ant-table-placeholder .ant-table-cell,
.Registers_itemsTable__PzX1n .ant-table-row .ant-table-cell {
  border: none !important;
}
.Registers_itemsTable__PzX1n .ant-table-placeholder .ant-table-cell {
  display: none;
}
.Registers_plusButtonItems__1yn1C {
  border: none;
  font-size: 25px;
  position: relative;
  top: 4px;
  padding: 0;
}
.Registers_invoiceItemFormDivider__1kCRj {
  margin: 0;
}

.Registers_addToRegisters__22m28 {
  width: 16px;
  height: 16px;
  color: #707070;
}

.Registers_addToRegisters__22m28 svg {
  width: 16px;
  height: 16px;
}

.Registers_inputAntdRow__295Ph .ant-row {
  margin-bottom: 0px;
}

.Registers_invoiceHeader__2iDyI {
  margin-bottom: 3px;
}

.Registers_sendButton__1RQ9- {
  background: #38414a;
  border-color: #38414a;
}

.Registers_sendButton__1RQ9-:hover {
  background: #38414aa8;
  border-color: #38414aa8;
}

.Registers_mailRow__16WN4 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 8px;
  gap: 8px;
}
.Registers_mailInput__2mK1r {
  border: none;
  width: 180px !important;
  margin-left: 10px;
}

.Registers_mailInput__2mK1r:focus {
  outline: none;
}
.Registers_mailList__376Ca {
  border-radius: 12px;
  padding: 4px;
  color: white;
  font-size: 13px;
  justify-content: space-around;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Registers_mailListInvalid__2xrun {
  background-color: #f25454d4;
}
.Registers_mailListValid__3iytm {
  background-color: #596776;
}

.Registers_mailListElementClose__NnipH {
  cursor: pointer;
}

.Registers_mailModalNote__EIJ8s {
  margin-top: 10px;
  font-size: 12px;
}
.Registers_labelForAddOrEditBuyer__2FuW- div {
  padding-bottom: 0 !important;
}
.Registers_labelForAddOrEditBuyer__2FuW- div label{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.Registers_labelForAddOrEditInvoice__2hqHU div {
  text-align: left;
}
.Registers_labelForAddOrEditInvoice__2hqHU div label {
  text-align: left;
  color: #707070d6;
  font-weight: 500;
  font-size: 13px;
}
.Registers_labelForAddOrEditInvoice__2hqHU div div div div div div span {
  text-align: center;
}
/*general profile styling */
.Profile_titleProfile__2n3rA {
  color: #43425d;
  font-weight: 400;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
  padding: 1rem 0.5rem;
}
.Profile_selectDropdownWithImg__ul6RB .ant-select-item-option-content {
  display: flex !important;
}
.Profile_imageUpload__wSomo {
  margin-right: 60px;
}
.Profile_imageWidth__2bvdD {
  width: 200px;
}
.Profile_hideSelectDropdown__2AnPS {
  display: none;
}
.Profile_uploadIcon__2AK_D {
  display: none;
}
.Profile_centerAlign__3m1JU {
  text-align: center;
}
.Profile_fullWidth__1JwB- {
  width: 100%;
  text-align: start;
}
.Profile_personalDetailsContainer__3vavg {
  width: 70%;
  max-width: 680px;
}
.Profile_tabsSection__1xzEM .ant-tabs-ink-bar {
  height: 4px;
  background-color: #458edf;
}
.Profile_tabsSection__1xzEM .ant-tabs-nav {
  font-weight: normal;
  color: #4b88df;
  font-size: 1rem;
}
.Profile_tabsSection__1xzEM .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: normal;
  color: #6c6c6c;
}
.Profile_tabsSection__1xzEM .ant-tabs-nav .ant-tabs-tab {
  margin-right: 30%;
  padding: 0.6rem 1rem;
}

/* Personal Details Section */

.Profile_personalDetailsTop__18bPr,
.Profile_personalDetailsBottom__kDIFj,
.Profile_companyDetailsBottom__1iQ9M {
  display: flex;
}
.Profile_personalDetailsBottom__kDIFj {
  justify-content: flex-end;
}

.Profile_personalDetailsTop__18bPr {
  justify-content: flex-end;
  border-bottom: 2px solid #f5f5f5;
}

.Profile_personalDetailsTop__18bPr .chooseFileButton {
  border: 2px solid #f5f5f5;
  background-color: #fff;
  font-weight: 500;
  color: #b3b3b3;
  padding: 0.4rem 2.1rem;
  margin: 0;
}
.Profile_personalDetailsTop__18bPr .chooseFileButton:hover {
  background-color: #f5f5f5;
}
.Profile_userInfo__1QIQa {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Profile_userInfo__1QIQa > p {
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
}
.Profile_userInfo__1QIQa .ant-input {
  padding: 0.4rem 1.2rem;
  border: 1px solid #f5f5f5;
  font-weight: 500;
  width: 300px;
  color: #b3b3b3;
}
.Profile_confirmInfoChangesBtn__1ninz,
.Profile_confirmPersonalChangesBtn__26YKq {
  background-color: #1abc9c;
  border-color: #1abc9c;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  font-weight: normal;
  height: auto;
}
.Profile_gutterBox__2SiTC {
  padding: 4px 4px;
}
.Profile_personalBottomLeft__1Iy3Q {
  color: #808080;
  padding: 2rem;
  padding-right: 3rem;
  max-width: 300px;
}
.Profile_companyBottomLeft__29wEp {
  color: #808080;
  padding: 2.5rem;
}
.Profile_personalBottomLeft__1Iy3Q,
.Profile_companyBottomRight__3PSvm > h3 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #808080;
  padding-bottom: 2rem;
}
.Profile_companyBottomRight__3PSvm {
  margin-top: 2.5rem;
}

.Profile_contactBlock__1w407 {
  display: flex;
}
.Profile_personalDetailsBottom__kDIFj .ant-input,
.Profile_personalDetailsBottom__kDIFj .ant-select-selector {
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}

.Profile_companyBottomRight__3PSvm .ant-input,
.Profile_companyBottomRight__3PSvm .ant-select-selector {
  width: 260px;
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}
.Profile_personalDetailsBottom__kDIFj .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid #f5f5f5;
  height: 38.78px; /* quick fix */
  margin: 0;
}
.Profile_datePickerItem__3N1e6 {
  width: 100%;
  height: 38.78px;
  border: 2px solid #f5f5f5;
}
.Profile_personalBottomRight__2rS-A {
  padding-top: 2rem;
  text-align: right;
  max-width: 300px;
}

/* Company Profile */

.Profile_formItemRow__1H_6A{
  display: flex;
}

.Profile_companyDetailsContainer__2B73W{
  display: flex;
  justify-content: center;
}

.Profile_companyDetailsForm__23RCv{
  width: 70%;
  max-width: 700px;
}
.Profile_ConfirmInfoChangesItem__1lCoZ div{
  display: flex;
align-items: flex-end;

}
.Profile_ConfirmInfoChangesItem__1lCoZ{

  display: flex;
  justify-content: flex-end;
}
.Profile_ConfirmInfoChangesItemMiddle__39DW1 div{
  display: flex;
justify-content: center;
align-items: center;

}
.Profile_ConfirmInfoChangesItemMiddle__39DW1{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Profile_headingCompany__2y4X8{
  color: #4d4f5c;
font-size: 1rem;
border-bottom: 2px solid #f5f5f5;
padding: 0 1rem 1rem 1rem;
}

.Profile_avatarUplouder__1khV3 > div{
  width: 140px !important;
  height: 140px !important;

}
/* Change Password */

.Profile_securityTabContainer__P52Oz > h2,
.Profile_companyDetailsContainer__2B73W > h2,
.Profile_personalDetailsContent__1G037 > h2 {
  color: #4d4f5c;
  font-size: 1rem;
  border-bottom: 2px solid #f5f5f5;
  padding: 0 1rem 1rem 1rem;
}

.Profile_securityTab__24td- {
  display: flex;
  margin-top: 2rem;
}

.Profile_securityTab__24td- .ant-input-password {
  width: 260px;
  padding: 0.2rem 1.2rem;
  height: auto;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}
.Profile_securityTab__24td- > p {
  font-weight: 500;
  color: #808080;
  font-size: 1rem;
  padding: 0 4rem;
}

@media (max-width: 1100px) {
  .Profile_personalDetailsTop__18bPr,
  .Profile_personalDetailsBottom__kDIFj,
  .Profile_companyDetailsBottom__1iQ9M {
    flex-direction: column;
    align-items: center;
  }
  .ant-input {
    width: 100% !important;
  }
  .Profile_companyBottomLeft__29wEp {
    padding: 1rem;
  }
  .Profile_companyBottomRight__3PSvm {
    margin-top: 0;
  }
  .Profile_securityTab__24td- {
    flex-direction: column;
    align-items: center;
  }
  .Profile_personalDetailsContainer__3vavg {
    width: 100%;
  }
}

.Spinner_ldsRoller__T1F5i {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 53%;
  height: 80px;
  margin-left: 47%;
  margin-bottom: 80px;
}
.Spinner_ldsRoller__T1F5i div {
  -webkit-animation: Spinner_ldsRoller__T1F5i 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Spinner_ldsRoller__T1F5i 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.Spinner_ldsRoller__T1F5i div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #082d6d;
  margin: -4px 0 0 -4px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes Spinner_ldsRoller__T1F5i {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_ldsRoller__T1F5i {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PageNotFound_container__2Jmt7 {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PageNotFound_title__1QMJ7 {
  font-size: 50px;
}

.PageNotFound_goBack__lZtQ7 {
  margin-top: 50px;
}

