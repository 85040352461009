@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.ant-layout-sider-collapsed .logo img {
  width: 100%;
}
.ant-layout-header {
  background: #22282d;
  color: #fff;
}

.ant-layout-sider-collapsed .logo span {
  display: none;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 33px 10px;
  color: #fff;
  background: #22282d;
  cursor: pointer;
}

.logo img {
  display: block;
  width: 70%;
  margin: 0 auto;
}
.service-desk-link {
  padding-left: 24px;
  color: #a6b5cc;
  transition: color 0.4s;
}
.service-desk-link:hover {
  color: #fff;
}
.service-desk-link span {
  padding-left: 6px;
}
.ant-layout-sider-collapsed .service-desk-link {
  text-align: center;
  padding: 0;
  display: block;
  font-size: 20px;
}
.ant-layout-sider-collapsed .service-desk-link span {
  display: none;
}

.logo span {
  position: relative;
  top: 5px;
}

.custom-menu-item {
  display: block;
  width: 100%;
  height: 100%;
}

/* #nprogress .bar {
  background: #1890ff;
}

#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}

#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
} */

.has-error {
  font-size: 10px;
}
.ant-layout-sider-children {
  overflow: auto;
}

.ant-layout-sider {
  background: #38414a;
}

.ant-menu.ant-menu-dark {
  background: #38414a;
}
#menu .ant-menu-item {
  margin: 0 !important;
  height: 55px;
  display: flex;
  align-items: center;
}

.ant-menu.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #596776;
  border-bottom: 1.5px solid #c3cad2;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #012d6d;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
}

.ant-layout-sider-trigger {
  background: #596776;
}
.ant-layout-sider-zero-width-trigger {
  top: 10px;
  z-index: 9999;
}
@media (max-width: 600px) {
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
}
.export-btn {
  margin: 0.8%;
}

/* GLOBAL STYLES: */
.divider {
  background: #f0f2f6;
  margin-top: 4%;
  width: 5000px; /* TODO: WTF??? */
  padding-left: 0;
  margin-left: -25px;
  padding: 5px;
  font-size: 1.4em;
}

h1 {
  font-size: 2em;
}

.modal-button {
  width: 100%;
  overflow: hidden;
}

/* HEADER styles */

.button-rocket {
  display: flex;
  align-items: center;
  background: #f9ae3b 0% 0% no-repeat padding-box;
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 1rem;
  height: 45px;
  font-family: 'Montserrat', sans-serif;
}
.button-rocket:hover {
  background-color: #f9ad3bdc;
  color: #fff;
}

.header-new-camp-middle {
  display: flex;
  align-items: center;
}
.button-rocket img {
  width: 45px;
  transition: 0.3s;
}
.button-rocket:hover img {
  transform: translateY(-5px);
}

.header-wallet {
  text-align: right;
  width: 30%;
  white-space: nowrap;
  margin-left: 20px;
}
.coins-value {
  color: #032b6d;
  font: Bold 1.4rem/32px Source Sans Pro;
  margin-right: 20px;
}
.wallet {
  width: 28px;
  padding-bottom: 10px;
  margin-right: 7px;
}
.header-right {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-end;
}

.header-right svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.header-dropdown {
  top: 50px !important;
}

.header-action-btn {
  color: #bcbccb;
  margin-left: 20px;
}
.header-action-btn:nth-child(2) {
  margin-right: 10px;
}
.header-action-btn:hover {
  color: #2d71b6;
  transition: 0.3s;
}
.header-username {
  font-weight: 600;
}
.profile-avatar {
  width: 41px;
  height: 41px;
  object-fit: cover;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
}

.down-arrow svg {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.header-right-spinner {
  width: 30%;
}
.header-right-user {
  white-space: nowrap;
  cursor: pointer;
  float: right;
  margin-right: 20px;
  padding-left: 10px;
}
.billingDataMissingNotification {
  height: 40px;
  background: rgb(250, 191, 98);
  display: flex;
  align-content: center;
  justify-content: center;
  color: #032b6d;
}
/* End Header styles */

@media (max-width: 1500px) {
  .coins-value {
    font-size: 1.2rem;
  }
  /* .header-username {
    display: none;
  } */
  .button-rocket {
    font-size: 0.9rem;
    padding: 0.5rem 0.7rem;
    height: 40px;
  }
  .header-right {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 900px) {
  .header-username,
  .header-wallet,
  .header-action-btn {
    display: none;
  }
}
