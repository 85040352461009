/*general profile styling */
.titleProfile {
  color: #43425d;
  font-weight: 400;
  font-size: 1.4rem;
  border-bottom: 1px solid #f5f5f5;
  padding: 1rem 0.5rem;
}
.selectDropdownWithImg :global(.ant-select-item-option-content) {
  display: flex !important;
}
.imageUpload {
  margin-right: 60px;
}
.imageWidth {
  width: 200px;
}
.hideSelectDropdown {
  display: none;
}
.uploadIcon {
  display: none;
}
.centerAlign {
  text-align: center;
}
.fullWidth {
  width: 100%;
  text-align: start;
}
.personalDetailsContainer {
  width: 70%;
  max-width: 680px;
}
.tabsSection :global(.ant-tabs-ink-bar) {
  height: 4px;
  background-color: #458edf;
}
.tabsSection :global(.ant-tabs-nav) {
  font-weight: normal;
  color: #4b88df;
  font-size: 1rem;
}
.tabsSection :global(.ant-tabs-nav .ant-tabs-tab-active) {
  font-weight: normal;
  color: #6c6c6c;
}
.tabsSection :global(.ant-tabs-nav .ant-tabs-tab) {
  margin-right: 30%;
  padding: 0.6rem 1rem;
}

/* Personal Details Section */

.personalDetailsTop,
.personalDetailsBottom,
.companyDetailsBottom {
  display: flex;
}
.personalDetailsBottom {
  justify-content: flex-end;
}

.personalDetailsTop {
  justify-content: flex-end;
  border-bottom: 2px solid #f5f5f5;
}

.personalDetailsTop :global(.chooseFileButton) {
  border: 2px solid #f5f5f5;
  background-color: #fff;
  font-weight: 500;
  color: #b3b3b3;
  padding: 0.4rem 2.1rem;
  margin: 0;
}
.personalDetailsTop :global(.chooseFileButton):hover {
  background-color: #f5f5f5;
}
.userInfo {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.userInfo > p {
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
}
.userInfo :global(.ant-input) {
  padding: 0.4rem 1.2rem;
  border: 1px solid #f5f5f5;
  font-weight: 500;
  width: 300px;
  color: #b3b3b3;
}
.confirmInfoChangesBtn,
.confirmPersonalChangesBtn {
  background-color: #1abc9c;
  border-color: #1abc9c;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  font-weight: normal;
  height: auto;
}
.gutterBox {
  padding: 4px 4px;
}
.personalBottomLeft {
  color: #808080;
  padding: 2rem;
  padding-right: 3rem;
  max-width: 300px;
}
.companyBottomLeft {
  color: #808080;
  padding: 2.5rem;
}
.personalBottomLeft,
.companyBottomRight > h3 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #808080;
  padding-bottom: 2rem;
}
.companyBottomRight {
  margin-top: 2.5rem;
}

.contactBlock {
  display: flex;
}
.personalDetailsBottom :global(.ant-input),
.personalDetailsBottom :global(.ant-select-selector) {
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}

.companyBottomRight :global(.ant-input),
.companyBottomRight :global(.ant-select-selector) {
  width: 260px;
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}
.personalDetailsBottom :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
  border: 2px solid #f5f5f5;
  height: 38.78px; /* quick fix */
  margin: 0;
}
.datePickerItem {
  width: 100%;
  height: 38.78px;
  border: 2px solid #f5f5f5;
}
.personalBottomRight {
  padding-top: 2rem;
  text-align: right;
  max-width: 300px;
}

/* Company Profile */

.formItemRow{
  display: flex;
}

.companyDetailsContainer{
  display: flex;
  justify-content: center;
}

.companyDetailsForm{
  width: 70%;
  max-width: 700px;
}
.ConfirmInfoChangesItem div{
  display: flex;
align-items: flex-end;

}
.ConfirmInfoChangesItem{

  display: flex;
  justify-content: flex-end;
}
.ConfirmInfoChangesItemMiddle div{
  display: flex;
justify-content: center;
align-items: center;

}
.ConfirmInfoChangesItemMiddle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.headingCompany{
  color: #4d4f5c;
font-size: 1rem;
border-bottom: 2px solid #f5f5f5;
padding: 0 1rem 1rem 1rem;
}

.avatarUplouder > div{
  width: 140px !important;
  height: 140px !important;

}
/* Change Password */

.securityTabContainer > h2,
.companyDetailsContainer > h2,
.personalDetailsContent > h2 {
  color: #4d4f5c;
  font-size: 1rem;
  border-bottom: 2px solid #f5f5f5;
  padding: 0 1rem 1rem 1rem;
}

.securityTab {
  display: flex;
  margin-top: 2rem;
}

.securityTab :global(.ant-input-password) {
  width: 260px;
  padding: 0.2rem 1.2rem;
  height: auto;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}
.securityTab > p {
  font-weight: 500;
  color: #808080;
  font-size: 1rem;
  padding: 0 4rem;
}

@media (max-width: 1100px) {
  .personalDetailsTop,
  .personalDetailsBottom,
  .companyDetailsBottom {
    flex-direction: column;
    align-items: center;
  }
  :global(.ant-input) {
    width: 100% !important;
  }
  .companyBottomLeft {
    padding: 1rem;
  }
  .companyBottomRight {
    margin-top: 0;
  }
  .securityTab {
    flex-direction: column;
    align-items: center;
  }
  .personalDetailsContainer {
    width: 100%;
  }
}
