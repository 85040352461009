.createRegisterBtn {
  margin-bottom: 30px;
}

.actions {
  display: flex;
  align-items: center;
}

.actions > div {
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}

.actions > div:first-child {
  margin-right: 12px;
}

.actions > div:last-child {
  margin-right: 0;
}

.actions :global(.ant-popover-inner-content) {
  padding: 5px 8px !important;
}

.addOrEditRegisterModal :global(.ant-modal-header .ant-modal-title) {
  font-size: 1.4em;
}

.modalFormWrapper,
.selectImgWrapper {
  display: flex;
}

.addOrEditRegisterModal :global(.ant-select-selection-item-content),
.addOrEditRegisterModal :global(.ant-select-selection-item-content) {
  display: flex !important;
}

.addOrEditRegisterModal :global(.ant-space-horizontal) {
  align-items: flex-start;
}

.modalFormWrapper :global(.ant-select-selection-search-input) {
  margin-left: 0 !important;
}

.selectDropdownWithImg :global(.ant-select-item-option-content) {
  display: flex !important;
}

.hideSelectDropdown {
  display: none;
}

.modalFormWrapper {
  flex-direction: column;
}

.widthModal50 {
  width: 50%;
}

.marginBotModal {
  margin-bottom: 20px;
}

.registersTable {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
th,
td {
  min-width: 80px;
}

.registersTable table thead th th:hover {
  font-size: 1.05em !important;
  background-color: #b3c8e617;
  color: #707070;
  font-weight: 600 !important;
}

.registersTable table tbody {
  font-size: 0.9rem;
  color: #707070;
}
.registersTable table tbody tr td {
  font-weight: 400;
}
.creatorAndDateCreated {
  font-size: 0.9em;
  font-weight: 500;
  color: #00000099;
}

.tableFooter {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}
.paginationDesription {
  margin-left: 20px;
  font-size: 0.9rem;
  color: #707070;
}
.headingActions {
  display: flex;
  margin-bottom: 30px;
  flex-direction: row-reverse;
  margin-bottom: 30px;
  justify-content: space-between;
}

.headingActions > div {
  margin-right: 20px;
}

.searchInput {
  margin: 0 -5px;
  display: flex;
  flex-direction: row;
  width: 300px;
}
.addButton,
.addButton:focus {
  background: #1abc9c;
  border: 1px solid #1abc9c;
  font-weight: bold;
}
.paginationBackground {
  background-color: white;
}
.addButton:hover {
  background: #1abc9cce;
}
.cancelButton {
  color: #fff !important;
  background: #f25454d4 !important;
  border-color: #f25454d4 !important;
  font-weight: bold;
}
.cancelButton:hover {
  background: #f25454ac;
  color: #fff;
  border-color: #f25454ac;
}
.pageSizeButton {
  margin-left: 14px;
}
.invoiceCustomInput {
  border: none;
  padding: 0;
  outline: none;
  border-bottom: 2px dotted #1abc9c9e;
  text-align: center;
  width: 100%;
  color: #1abc9c;
  background: transparent;
  font-size: 13px;
  height: 27px;
}

.invoiceCustomInput :global(input) {
  text-align: center;
  color: #1abc9c;
}
.invoiceCustomInput :global(.ant-select-selector) {
  border: none !important;
  justify-content: center;
  background: transparent !important;
}
.invoiceCustomInput :global(.ant-select-selector .ant-select-selection-item div) {
  justify-content: center;
  background: transparent !important;
}
.formTwoSetFields {
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: 45px;
  font-size: 13px;
}
.invoiceAndBuyerFormBoxes {
  padding: 22px !important;
  background-color: white;
  color: #707070d6;
  font-weight: 500;
}
.formTwoSetFields:nth-child(odd) {
  background-color: #1abc9c0e;
}
.invoicePlaceholderDisabled::placeholder {
  color: #f25454ea;
}
.invoiceCustomInput[disabled] {
  background-color: transparent;
}
.invoiceCustomInput:focus {
  box-shadow: none;
  border-color: #1abc9c;
}
.invoiceSubHeader {
  margin-bottom: 14px;
  color: #707070d6;
  font-weight: 500;
}
.customerColumn {
  width: 25%;
  display: inline-block;
  padding: 0 10px;
}
.registerForm {
  width: 100%;
}
.formColumn {
  text-align: center;
  padding: 0 10px;
  width: 10%;
  display: inline-block;
  margin-left: 1px;
}
.cursorPointer {
  cursor: default;
}
.itemsTable :global(.ant-empty.ant-empty-normal) {
  display: none;
}
.itemsTable :global(.ant-table-thead .ant-table-cell),
.itemsTable :global(.ant-table-placeholder .ant-table-cell),
.itemsTable :global(.ant-table-row .ant-table-cell) {
  border: none !important;
}
.itemsTable :global(.ant-table-placeholder .ant-table-cell) {
  display: none;
}
.plusButtonItems {
  border: none;
  font-size: 25px;
  position: relative;
  top: 4px;
  padding: 0;
}
.invoiceItemFormDivider {
  margin: 0;
}

.addToRegisters {
  width: 16px;
  height: 16px;
  color: #707070;
}

.addToRegisters :global(svg) {
  width: 16px;
  height: 16px;
}

.inputAntdRow :global(.ant-row) {
  margin-bottom: 0px;
}

.invoiceHeader {
  margin-bottom: 3px;
}

.sendButton {
  background: #38414a;
  border-color: #38414a;
}

.sendButton:hover {
  background: #38414aa8;
  border-color: #38414aa8;
}

.mailRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}
.mailInput {
  border: none;
  width: 180px !important;
  margin-left: 10px;
}

.mailInput:focus {
  outline: none;
}
.mailList {
  border-radius: 12px;
  padding: 4px;
  color: white;
  font-size: 13px;
  justify-content: space-around;
  width: fit-content;
}

.mailListInvalid {
  background-color: #f25454d4;
}
.mailListValid {
  background-color: #596776;
}

.mailListElementClose {
  cursor: pointer;
}

.mailModalNote {
  margin-top: 10px;
  font-size: 12px;
}
.labelForAddOrEditBuyer div {
  padding-bottom: 0 !important;
}
.labelForAddOrEditBuyer div label{
  height: fit-content;
}
.labelForAddOrEditInvoice div {
  text-align: left;
}
.labelForAddOrEditInvoice div label {
  text-align: left;
  color: #707070d6;
  font-weight: 500;
  font-size: 13px;
}
.labelForAddOrEditInvoice div div div div div div span {
  text-align: center;
}